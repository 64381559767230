import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {MediaCard} from 'components/cards/mediaCards'
import {TitleBanner} from 'components/banners/titleBanner'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import Style from './mediakit.module.css'
const MediaKitTemplate = (props) => (
  <Layout
    articleId={`${props.data.wordpressPage.type}-${props.data.wordpressPage.wordpress_id}`}
    articleClassName={`${props.data.wordpressPage.classes}`}
    context = {props.pageContext}
    location = {props.location}
  >
    <div className="container px-5 mx-auto relative">
      <TitleBanner title={props.data.wordpressPage.title} />
      <div className="entry">
        <div className="container mx-auto entry-content relative">
          <div className="text-center mb-20" dangerouslySetInnerHTML={{__html: props.data.wordpressPage.content}}/>

          <div className="container mx-auto jutify-start flex-wrap flex relative">
            <BgPattern className={`${BgStyle.pattern2} ${Style.bgTop}`}/>
            {props.data.wordpressPage.mediakit_items && props.data.wordpressPage.mediakit_items.length > 0 && props.data.wordpressPage.mediakit_items.map((item,index) => (
              <div style={{width:370}} className="mx-5 mb-5" key={index}>
                <MediaCard {...item}/>
              </div>
              
            ))}
          </div>
        </div>
      </div>
      <BgPattern className={`${BgStyle.pattern1} ${Style.bgBottom}`}/>
      <footer className="entry-footer"/>
    </div>
  </Layout>
);


export default MediaKitTemplate
export const MediaKitQuery = graphql `
query MediaKitById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    excerpt
    path
    type
    date
    wordpress_id
    classes
    mediakit_items {
      path
      type
      excerpt
      date(formatString:"LL")
      title
      isExternal
      link {
        url
        target
        alt
      }
      featured_media {
        localFile {
          childImageSharp{
            fluid(maxWidth: 370){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`
