import React from 'react'
import {LinkCard} from './linkCard'
import {DownloadCard} from './downloadCard'
import {VideoCard} from './videoCard'


export const MediaCard = (props) => {
  switch (props.type){
  case 'file':
    return <DownloadCard {...props}/>
  case 'video':
    return <VideoCard {...props}/>

  default:
    return <LinkCard {...props} />
  }
  
}

