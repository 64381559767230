import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Img from 'gatsby-image'
import CardStyle from './mediacard.module.css'
import {Link} from 'components/link'
import {ReactComponent as LinkIcon} from 'images/icons/arrow.svg'


const LinkButton = (props) => {
  return (
    <button className={CardStyle.button}><LinkIcon className="fill-current stroke-current"/></button>
  )
}

export const LinkCard = React.forwardRef( (props, ref) => (
  <div className={CardStyle.container} ref={ref}>
    
    {props.featured_media && props.featured_media.localFile && props.featured_media.localFile.childImageSharp &&
     <div className={CardStyle.media}>
       <Img
         fluid={props.featured_media.localFile.childImageSharp.fluid}
         className={CardStyle.image}
         style={{
           height:"100%",
           width: "100%"
         }}
     />
     </div>
      }

    <div className="flex items-center justify-between p-1 ">
      <div className="mr-5">
        {props.date && 
         <div className={CardStyle.top}>{props.date}</div>
        }
        <div className={CardStyle.title} dangerouslySetInnerHTML={{__html:props.title}}/>

      </div>
      <div>
        <Link to={props.path} target={props.isExternal?'_blank':''}><LinkButton/></Link>
      </div>
    </div>
  </div>
))
